<template>
  <div class="offcanvas offcanvasmenu offcanvas-end" tabindex="-1" id="offcanvasMenu" aria-labelledby="offcanvasMenuLabel">
    <div class="offcanvas-header position-fixed">
      <h5 id="offcanvasMenuLabel"></h5>
      <button type="button" class="btn-close text-reset btn-close-white" data-bs-dismiss="offcanvas" aria-label="Close"></button>
    </div>
    <div class="offcanvas-menu-body" style="height: 100%;">
      <div class="row h-100 align-content-center">
        <div class="col-m-12 h-100 canvas-menu">
                <div class="row g-0 h-100">
                    <div class="col-12 item-section">
                        <nav class="nav-menu-list">
                            <span style="margin-bottom: 20px">
                                <router-link 
                                :to="{
                                    name:  'home',
                                }" class="home" @click="this.closeOffCanvas">HOME</router-link>
                            </span>
                            <span style="margin-bottom: 20px">
                                <router-link 
                                :to="{
                                    name:  'residences',
                                }" class="residences" @click="this.closeOffCanvas">RESIDENCES</router-link>
                            </span>
                            <span style="margin-bottom: 20px">
                                <router-link 
                                :to="{
                                    name:  'amenities',
                                }" class="amenities" @click="this.closeOffCanvas">AMENITIES</router-link>
                            </span>
                            <span style="margin-bottom: 20px">
                                <router-link 
                                :to="{
                                    name:  'location',
                                }" class="location" @click="this.closeOffCanvas">LOCATION</router-link>
                            </span>
                        </nav>
                    </div>
                </div>
        
            </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'OffCanvasMenu',
  props: [],
  data() {
    return {
    }
  },
  created() {
  },
  methods: {
    closeOffCanvas: function() {
        document.querySelector('.offcanvas-backdrop').remove()
        document.querySelector('body').style.overflow = null
        document.querySelector('body').style.paddingRight = null
    }
  },
  setup () {

    return {
    }
  }
};
</script>