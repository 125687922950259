<template>
    <main id="page-amenities">
        <Header />
        <div class="section-2">
            <video playsinline="" loop="" muted="" autoplay="" id="shortvideo" title="Section-6-Video" v-if="this.topbackgroundVideo">
                <source :src="this.theme.cdnUrl + '/website/amenities/' + this.topbackgroundVideo.videoobjectname" type="video/mp4">
            </video>
            <h1 class="section-1-primary-text">Copy Here</h1>
            <p class="section-1-sub-text">
                Lorem ipsum dolor sit amet,
                <br>
                consectetur adipiscing elit,
                <br>
                sed do eiusmod
            </p>
        </div>
        <div class="section-3">
            <div class="row">
                <div class="col-12 text-center">
                    <h1 class="title-text">Copy Here</h1>
                </div>
                <div class="col-12 text-center">
                    <p class="section-2-sub-text">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud</p>
                    <p class="section-2-sub-text">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud</p>
                </div>
            </div>
        </div>
        <div class="section-4">
            <div id="carouselExample" class="carousel slide" data-bs-ride="carousel">
                <div class="carousel-inner">
                    <div :class="{'carousel-item': true, 'active': image.id == 0}" v-for="image in carouselAssets">
                        <img :src="this.theme.cdnUrl + '/website/amenities/' + image.thumbnailobjectname" class="d-block w-100" alt="...">
                    </div>
                </div>
                <button class="carousel-control-prev" type="button" data-bs-target="#carouselExample" data-bs-slide="prev">
                    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span class="visually-hidden">Previous</span>
                </button>
                <button class="carousel-control-next" type="button" data-bs-target="#carouselExample" data-bs-slide="next">
                    <span class="carousel-control-next-icon" aria-hidden="true"></span>
                    <span class="visually-hidden">Next</span>
                </button>
            </div>
            <!-- <img src="../assets/img/amenities1.png" class="amenities-img1"> -->
        </div>
        <div class="section-4">
            <div class="row">
                <div class="col-12 col-sm-4 row m-0 p-0" v-for="asset in assetTypes">
                    <p :class="{'apartment-text': true, 'order-1 order-sm-2': asset.id%2!=0 }">{{asset.name}}</p>
                    <img :src="this.theme.cdnUrl + '/website/amenities/' + asset.thumbnailobjectname" :class="{'amenities-img1': true, 'order-2 order-sm-1': asset.id%2!=0 }" @click="this.togglePlayVideo(this.theme.cdnUrl + '/website/amenities/' + asset.videoobjectname,asset.id)">
                </div>
            </div>
        </div>
        <Footer />
        <FsLightbox
            :toggler="toggler"
            :sources="sources"
            :key="lightboxIndex"
        />
    </main>
</template>


<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import FsLightbox from "fslightbox-vue/v3";
import global from '../global'
import BunnyNetService from "@/services/BunnyNetService.js";

export default {
  name: "Amenities",
  props: {

  },
  components: {
    Footer,
    Header,
    FsLightbox
  },
  data() {
    return {
        toggler: false,
        lightboxIndex: 0,
        sources: [
        ],
        assetTypes: null,
        carouselAssets: null,
        folderStructure: encodeURIComponent(this.theme.devFolder + '/website/amenities/'),
        topbackgroundVideo: null,
    }
  },
  created() {
    BunnyNetService.getRenders(this.folderStructure, 'websitetemplate')
      .then(response => {
        console.log(response)
        response.data.forEach(element => {
            if (element.ObjectName.includes('backgroundvideo')){
                console.log(element)
                this.topbackgroundVideo = {
                    name: "Background Video",
                    videoobjectname: element.ObjectName,
                    videoguid: element.Guid,
                    id: 1
                }

            }
                
        });

        let j = 0;
        // Remap array
        var assets = []
        this.theme.amenitiesbottomsection.forEach(item => {
            console.log(item.replace(" ", "").toLowerCase())
            assets.push({})
            response.data.forEach(element => {
                if (element.ObjectName.includes(item.replace(" ", "").toLowerCase())){
                    console.log(element)
                    if (element.ObjectName.includes("mp4")){
                        assets[j].videoobjectname = element.ObjectName
                        assets[j].videoguid = element.Guid
                        assets[j].id = j
                    }else{
                        assets[j].name = item
                        assets[j].thumbnailobjectname = element.ObjectName
                        assets[j].thumbnailguid = element.Guid
                        assets[j].id = j
                    }
    
                }
                
            });
            j++
        })
        this.assetTypes = assets

        var carouselassets = []
        var carouselIndex = 0
        response.data.forEach(element => {
            if (element.ObjectName.includes('carousel')){
                console.log(element)
                carouselassets.push({
                    name: "carousel" + carouselIndex,
                    thumbnailobjectname: element.ObjectName,
                    thumbnailguid: element.Guid,
                    id: carouselIndex
                })
                carouselIndex++

            }
                
        });

        this.carouselAssets = carouselassets

    })

  },
  methods:{
    togglePlayVideo: function(source, index) {
        console.log("playing video")
        this.lightboxIndex = index
        setTimeout(() => {
            this.sources = [source]
            this.toggler = !this.toggler
            setTimeout(() => {
                const video = document.querySelectorAll("video.fslightbox-video");
                if(video.length != 0){
                    console.log(video)
                    video[0].style.width = '100%'
                    setTimeout(() => {
                        video[0].style.width = '100%'
                    }, 200)
                }
            }, 100)
        }, 100);

    }
  },
  setup () {
    const {theme} = global

    return {
      theme
    }
  }
}
</script>