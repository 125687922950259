import { reactive } from "vue";

const theme = reactive({
  apiUrl: "https://api.metaluxe.com.au/",
  bunnyApiUrl: "https://api.bunny.net/",
  accessKey: "ec558f34-7538-4b7e-b822-179253457e6b93932c0d-eae6-47b4-b444-1ac035b8ff7f",
  storageApiPassword: "2f7933ae-ae2f-4fa2-9bdf2dbdd589-2b56-43d4",
  cdnUrl: "https://websitetemplate.b-cdn.net",
  devFolder: "/websitetemplate",
  identityPoolId: "ap-southeast-2:bae6c4d4-80de-4d12-bdb1-e359bb1f2ab8",
  to: ['ben@metaluxe.com.au'],
  site: "Template Website",

  residencebottomsection: [
    "Apartment 1",
    "Apartment 2",
    "Apartment 3"
  ],
  amenitiesbottomsection: [
    "Lounge",
    "BBQ",
    "Steam Room",
  ]
});


export default { theme };