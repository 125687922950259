<template>
    <main id="page-location">
        <Header />
        <div class="section-2">
            <video playsinline="" loop="" muted="" autoplay="" id="shortvideo" title="Section-6-Video" v-if="this.topbackgroundVideo">
                <source :src="this.theme.cdnUrl + '/website/location/' + this.topbackgroundVideo.videoobjectname" type="video/mp4">
            </video>
            <h1 class="section-1-primary-text">Copy Here</h1>
            <p class="section-1-sub-text">
                Lorem ipsum dolor sit amet,
                <br>
                consectetur adipiscing elit,
                <br>
                sed do eiusmod
            </p>
        </div>
        <div class="section-3">
            <div class="row">
                <div class="col-12 text-center">
                    <h1 class="section-3-header">Copy Here</h1>
                </div>
                <div class="col-12 col-sm-6 text-center">
                    <p class="section-2-sub-text">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud</p>
                    <router-link
                        :to="{
                            name:  'residences',
                        }"
                        class="btn btn-dark explore-btn">
                        EXPLORE APARTMENTS
                    </router-link>
                </div>
                <div class="col-12 col-sm-6 text-center">
                    <p class="section-2-sub-text">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud</p>
                    <router-link
                        :to="{
                            name:  'amenities',
                        }"
                        class="btn btn-dark explore-btn">
                        EXPLORE AMENITIES
                    </router-link>
                </div>
            </div>
        </div>
        <div class="section-4" v-if="images">
            <img :src="this.theme.cdnUrl + '/website/location/' + images[0].name" class="home-img1">
            <img :src="this.theme.cdnUrl + '/website/location/' + images[1].name" class="home-img2">
            <br>
            <img :src="this.theme.cdnUrl + '/website/location/' + images[2].name" class="home-img3">
        </div>
        <Footer />
    </main>
</template>


<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import global from '../global'
import BunnyNetService from "@/services/BunnyNetService.js";

export default {
  name: "Location",
  props: {

  },
  components: {
    Footer,
    Header
  },
  data() {
    return {
        folderStructure: encodeURIComponent(this.theme.devFolder + '/website/location/'),
        topbackgroundVideo: null,
        images: null,
    }
  },
  created() {
    BunnyNetService.getRenders(this.folderStructure, 'websitetemplate')
        .then(response => {
            console.log("here")

            var images = []
            var imageindex = 0

            response.data.forEach(element => {
                if (element.ObjectName.includes('backgroundvideo')){
                    console.log(element)
                    this.topbackgroundVideo = {
                        name: "Background Video",
                        videoobjectname: element.ObjectName,
                        videoguid: element.Guid,
                        id: 1
                    }

                }
                if (element.ObjectName.includes("hightlightimages")){
                    console.log(element)
                    images.push({
                        id: imageindex,
                        name: element.ObjectName,
                    })
                    imageindex++
                }
                    
            });
            console.log(this.topbackgroundVideo)
            this.images = images
    })
  },
  setup () {
    const {theme} = global

    return {
        theme
    }
  }
}
</script>