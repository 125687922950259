import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import ResidencesView from '../views/ResidencesView.vue'
import AmenitiesView from '../views/AmenitiesView.vue'
import LocationView from '../views/LocationView.vue'

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: [
    {
      path: '/',
      name: 'home',
      component: HomeView
    },
    {
      path: '/residences',
      name: 'residences',
      component: ResidencesView
    },
    {
      path: '/amenities',
      name: 'amenities',
      component: AmenitiesView
    },
    {
      path: '/location',
      name: 'location',
      component: LocationView
    }
  ],
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { top: 0 }
    }
  },
})

export default router
