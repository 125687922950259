<template>
    <div class="footer-1" id="footer-1">
        <h3 class="enquire-header">Enquire Now</h3>
        <h4 class="enquire-number">Number Here</h4>
        <div id="form_thanks" style="display: none; margin: 8em 0px">
            <h4 class="enquire-number">Thanks for your enquiry.</h4>
        </div>
        <form id="contact_form" v-on:submit="myFunction">
            <input type="text" name="firstName" id="firtname" required="" placeholder="First Name:*" class="form-inputs">
        
            <input type="text" name="lastName" id="lastname" required="" placeholder="Last Name:*" class="form-inputs">
        
            <input type="tel" name="phone" id="phone" data-parsley-length="[8, 14]" data-parsley-type="digits" onkeyup="this.value = this.value.replace(/\D/g, '')" required="" placeholder="Phone:*" class="form-inputs">
        
            <input type="email" name="email" id="email" data-parsley-type="email" required="" placeholder="Email:*" class="form-inputs">
        
            <select name="hearAboutUs" id="enquiry_enquiry_source" size="1" required="" class="form-inputs">
                <option selected="selected" value="" disabled="disabled">How did you hear about us? *</option>
                <option value="Apartments &amp; Developments">Apartments &amp; Developments</option>
                <option value="Billboard">Billboard</option>
                <option value="Domain.com.au">Domain.com.au</option>
                <option value="Facebook">Facebook</option>
                <option value="Google">Google</option>
                <option value="Instagram">Instagram</option>
                <option value="Realestate.com.au">Realestate.com.au</option>
                <option value="Signage">Signage</option>
                <option value="Street Furniture">Street Furniture</option>
                <option value="Urban.com">Urban.com</option>
            </select>
                        
            <input type="submit" value="Submit" class="submit_btn btn btn-light btn-block">
        </form>
    </div>
    <div class="footer-2"><h1 class="footer-logo">Your Logos</h1></div>
    <div class="footer-3">
        <a href="https://www.metaluxe.com.au/term/privacy-policy" target="_blank" style="text-decoration: none;">
            <p class="footer-links">Privacy Policy</p>
        </a>
        <div class="footer-divider"></div>
        <a href="https://www.metaluxe.com.au/term/terms-conditions" target="_blank" style="text-decoration: none;">
            <p class="footer-links">Terms & Conditions</p>
        </a>
        <div class="footer-divider"></div>
        <a href="https://www.metaluxe.com.au/" target="_blank" style="text-decoration: none;">
            <p class="footer-links">MetaLuxe</p>
        </a>
    </div>
</template>


<script>
import global from '../global'
import AWS from 'aws-sdk'

export default {
  name: "Footer",
  props: {

  },
  components: {
  },
  data() {
    return {
    }
  },
  created() {

  },
  methods: {
    myFunction: function(e) {
        e.preventDefault()
        AWS.config.region = "ap-southeast-2";
        AWS.config.credentials = new AWS.CognitoIdentityCredentials({
            IdentityPoolId: this.theme.identityPoolId,
        });

        // Create client.
        var lambda = new AWS.Lambda();
        var firstname = document.getElementById('firtname').value
        var lastname = document.getElementById('lastname').value
        var mobile = document.getElementById('phone').value
        var email = document.getElementById('email').value
        var hearaboutus = document.getElementById('enquiry_enquiry_source').value
        const params = {
            FunctionName: 'Metaluxe-Website-Enquiry', /* required */
            Payload: JSON.stringify( {
                FirstName: firstname,
                LastName: lastname,
                Mobile: mobile,
                Email: email,
                HearAboutUs: hearaboutus,
                To: this.theme.to,
                Site: this.theme.site,
            })
        };
        console.log(params)
        lambda.invoke(params,  function (err, data){
            if (err) console.log(err, err.stack); // an error occurred
            else {
                console.log('Success, payload', data)
                console.log("hi")
                document.getElementById('form_thanks').style.display = "block"
                document.getElementById('contact_form').style.display = "none"
            };           // successful response
        })
    }
  },
  setup () {
    const {theme} = global

    return {
      theme
    }
  }
}
</script>