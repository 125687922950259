<template>
    <div class="section-1">
        <a href="#menu" id="nav_toggle" class="mobile" data-bs-toggle="offcanvas" data-bs-target="#offcanvasMenu" aria-controls="offcanvasMenu" @click="this.menuToggle">
            <span class=""></span>
        </a>
        <nav class="desktop">
            <router-link 
            :to="{
                name:  'home',
            }" class="home">HOME</router-link>
            <router-link 
            :to="{
                name:  'residences',
            }" class="residences">RESIDENCES</router-link>
            <router-link 
            :to="{
                name:  'amenities',
            }" class="amenities">AMENITIES</router-link>
            <router-link 
            :to="{
                name:  'location',
            }" class="location">LOCATION</router-link>
        </nav>
        <div class="header-logo">
            <h1 class="header-logo-text">Your Project Logo</h1>
        </div>
        <div class="header-right desktop">
            <p style="margin: 0.5em; margin-right: 40px;">NUMBER HERE</p>
            <a href="#footer-1" class="btn btn-dark">ENQUIRE NOW</a>
        </div>
    </div>
    <OffCanvasWebsiteMenu />
</template>

<script>
import OffCanvasWebsiteMenu from "./OffCanvasWebsiteMenu.vue";

export default {
  name: "Header",
  components: {
    OffCanvasWebsiteMenu,
  },
  data() {
    return {
        menu: false
    }
  },
  created() {
  },
  methods: {
    menuToggle: function() {
        if (this.menu == false){
            this.menu = true
            document.querySelector('#nav_toggle').classList.add("active")

        }else{
            this.menu = false
            document.querySelector('#nav_toggle').classList.remove("active")
        }
    }
  },
  setup () {

    return {
    }
  }
};
</script>
